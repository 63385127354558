import { Home, About, Products } from '../pages';

const routes = [
  {
    label: 'Overview',
    path: '/',
    component: Home,
  },

  {
    label: 'Products',
    path: '/products',
    component: Products,
  },
  {
    label: 'About',
    path: '/about',
    component: About,
  },
];

export default routes;
